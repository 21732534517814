import "./NewAddressModal.css";

import { useState, useEffect } from "react";

const NewAddressModal = ({ callFunction }) => {
    const [showNewAddressModal, setNewAddressModal] = useState(true);

    const toggleModal = () => {
        setNewAddressModal(!showNewAddressModal);
        callFunction(false);
    }

    // event listener to close modal when clicked outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.className === "new-address-modal") {
                setNewAddressModal(false);
                callFunction(false);
            }
        }

        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, );

    return (
        <>
            {showNewAddressModal &&
                <div id="new-address-modal" className="new-address-modal">
                    <div className="new-address-modal-content">
                        <div className="new-address-modal-header">
                            <h2>River City Cabinets New Address</h2>
                            <span className="new-address-modal-close" onClick={toggleModal}>&times;</span>
                        </div>
                        <div className="new-address-modal-body">
                            <p>2025 News</p>
                            <p>We've moved to Suite 100 at the same address to better serve you:</p>
                            <p><b>2603 Sundance Road, Suite 100, Nampa ID, 83651</b></p>
                            <p>Thank you for your continued support. We look forward to serving you at our new location!</p>
                        </div>
                        <div className="new-address-modal-footer">
                            <h3>Contact us for any question</h3>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default NewAddressModal;